export const environment = {
  production: true,
  slug: 'demo',
  apiUrl: 'https://api-demo.shoutly.com',
  baseUrl: 'https://demo.shoutly.com',
  recaptcha: {
    siteKey: '6LdjdB4eAAAAAB8nfwV0XTwvKbaqo1w4oOLx8mh9'
  },
  paypal: {
    apiUrl: 'https://api-m.sandbox.paypal.com',
    connectUrl: 'https://www.sandbox.paypal.com/connect',
    account: 'sb-vojuv21112853@business.example.com',
    clientId: 'AXb5gxKZVdUnV6M3IXsWX3z8oyC8nPg4N3-aAj7hEvWLV0WThAZ3Fbvz-GCBQSW4kNajHsdzsEzHqqr_',
    secret: 'EDOUlKdh7HabzTU1egNq8R6GBxpiRSUGXkL_z9J3MtNYonyptR1ANM3atd9XpCv6iIcUJkcGWUKS7fFD'
  },
  google: {
    recaptcha: {
      siteKey: '6LdjdB4eAAAAAB8nfwV0XTwvKbaqo1w4oOLx8mh9'
    },
    analytics: {
      measurement_id: 'G-RLCGTYVBLM'
    }
  },
  storeEncryptionSecretKey: 'sXCaWU7myxS5QEm4',
  fortnox: {
    clientId: 'D0rv5MMTSUP3'
  }
}
